const ProjectStatus = () => {
  return [
    "All",
    "Active",
    "ALMOST FUNDED",
    "Completed",
    "Today",
    "This Week",
    "This Month",
    "This Year",
  ];
};

export default ProjectStatus;
