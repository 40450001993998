import React from "react";

import ProjectDetailsContentContainer from "../Containers/ProjectDetailsContentContainer";

const ProjectDetailsPage = () => {

  return (
      <ProjectDetailsContentContainer />
  );
};

export default ProjectDetailsPage;
