const Breakponts = {
  values: {
    xs: 0,
    sm: 750,
    md: 1020,
    lg: 1500,
    xl: 10000,
  },
};

export default Breakponts;
